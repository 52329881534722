import React from 'react';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Navbar from '../../Components/Navbar/Navbar';
import NewsBox from '../../Components/NewsBox/NewsBox';
import SubscriptionBox from '../../Components/SubscriptionBox/SubscriptionBox';
import HeaderPhoto from './Assets/ArticleOneHeaderPhoto.svg';
import './Newsroom.css';

const ArticleOne = () => {
    return (
        <div id='ArticleOne'>
            <Navbar />
            <Header background={HeaderPhoto}
                height={'31.0625em'}
                display={'none'} />
            <NewsBox Header={'Investing In Africa Oil & Gas? Top 5 Forums You Should Attend'}
                DateTag={'Jan 6, 2023'}
                Display={'none'}
                BodyI={'Oil and Gas (petroleum) is almost as vital to human existence as water, because petroleum is used in every day-to-day activity, Petroleum remains in high demand. Historically, the oil and gas sector have played key roles in diversifying the economy. Investing in oil and gas is one of the most efficient ways of generating income /revenue as an investor; however, it is very sensitive. It appears very complex if not well understood. It may be of interest to note, that- “Risk comes from not knowing what you are doing”…..Warren Buffet…'}
                BodyII={'Hence, it is important to know as much about investing in oil and gas before venturing into it. Investing in oil and gas is usually a long-term one so it requires patience. This article reveals some of the investment opportunities in the oil and gas sector and suggests the top five (5) oil and gas investment opportunity forum for intending investors in Africa oil and gas. There are different kinds of investment opportunities in the upstream or downstream segments of the oil and gas industry. They include but are not limited to: Exploration and Production: This deals mainly with the search, exploration, drilling and extraction of oil. It is the earliest phase of oil and gas production process.'}
                BodyIII={'Typical investment here is the acquisition Oil Prospecting License (OPL) which covers the exploration phase and the Oil Mining Licence (OML) which includes the production phase.Working Capital Partnership The oil and gas industry is cash intensive, A key challenge is how to access and manage cash demands necessary to meet monthly and sometimes even daily demands operations. Oil and gas companies typically source their funding from banks, cashcall from joint venture partners, and share capital from equity investors. Investors can provide funds as “working capital partners” to cover: accounts receivable, accounts payable and inventories. Indeed, an improved cash culture can enhance cash flow and ensure required changes are sustainable. Services and Support: This form of investment deals mainly with providing supporting services. Examples include transportation, shipping and logistics, construction and engineering, drilling and refining hardware and equipment. One strategy that an independent investor can adopt is to specialize in providing supports and services like a hydrographic survey on oil sites, mud pit construction works at rig sites, seismic data interpretation, drilling operations, and storage tank farm. Top exploration and production companies may require these specialized services on contracts. Becoming a specialist in the provision of the above-listed services can set you apart in the oil and gas market. Top 5 Forums To Attend - Investments In Oil And Gas In Africa'}
                TopicIV={'1. AFRICA OIL WEEK (AOW) https://www.africa-oilweek.com '}
                BodyIV={'The Africa oil week (AOW) is a meeting place for Africa’s senior exploration and production stakeholders; it attracts international oil companies, governments, independent investors from all over Africa. They come to set out a future direction of the continent’s upstream oil and gas sector. In that meeting, bidding rounds are held, major deals are secured. The AOW is regarded as the best place to discover or secure investment opportunities in oil and gas. Several African countries use the African oil week to promote their oil and gas sector to a global audience of investors. As an investor, if you follow up on the activities of the AOW, you are likely to be exposed to many investment opportunities.Venue: CTICC 2, Cape Town, South Africa. The AOW is usually held in February. The next Event holds from 1st to 5th Feb. 2021'}
                TopicV={'2. AFRICA OIL AND POWER EVENTS (AOP) https://www.africaoilandpower.com'}
                BodyV={'Africa oil and power is one of Africa’s leading investment platform for the energy sector. Through a series of events, online content and investment reports, they unite the entire energy value chain from oil and gas exploration to renewable power and facilitate global and intra-African investment and collaboration. Africa oil and power events bring together the top leaders in the oil and power sectors, from government officials to corporate executives. It is a place to meet dealmakers and make deals. AOP events are organized in a set of African countries with different dates and schedules. Some upcoming AOP events across Africa are:'}
                Point5I={'a) South Sudan Oil and Power 2021 (SSOP)Venue: The Crown Hotel juba, South Sudan. Date: 29-30 June 2021'}
                Point5II={'b) Gabon Oil and Power, 2021 Venue: TBC in Libreville Date: 15-16 |March, 2021'}
                Point5III={'c) Angola Oil and Power Conference Venue: The Talatona Convention centre in Luanda Date: 14-15 October 2020'}
                TopicVI={'3. AFRICA ASSEMBLY https://oilandgascouncil.com/event-events/africa-assembly'}
                BodyVI={'The Oil and Gas council’s Africa assembly is recognized as the region’s most influential corporate, development, strategy, finance and investment gathering that bring together the African energy industry community with the financiers and investors backing innovative projects across the continent. The event is organized by Africa oil and power. The Venue and date is usually determined by the Africa Oil and Power Schedule'}
                TopicVII={'4. INVEST IN AFRICA CONFERENCE www.afsic.net'}
                BodyVII={'(AFSIC- investing in Africa) is the largest annual “invest in Africa” conference. It sometimes takes place outside Africa with an average of over 1500-delegate forecast, including many of Africa’s most important investors, dealmakers and business leaders from all sectors including oil and gas sector. It usually features 0ver 350-speaker spread across 3 days in up to five (5) parallel streams of presentation and plenaries. The “invest in Africa conference- AFSIC” has become one of the most important Africa events globally. It has a unique focus on matching Africa’s top investors including those from the oil and gas sector, with investment opportunities from across the continent. The agenda is highly focused on investment and the event has multiple opportunities for delegates to –meet the top Africa investors, profile their Africa business opportunity, grow their Africa network and extend their business across all parts of Africa. AFSIC Investing in Africa usually holds by May. The next event comes up on the 5th to 7th May 2021 at Park Plaza Westminster Bridge, London.'} 
                TopicVIII={'5. OFFSHORE TECHNOLOGY CONFERENCE (OTC) www.otcnet.org'}
                BodyVIII={"The offshore technology conference (OTC) is where energy professionals meet to exchange ideas and opinions to advance scientific and technical knowledge for offshore resources and environmental matters. The OTC networks thousands of energy professionals from over 100 countries including African countries with over 2000 leading providers of product and services across the world. The OTC features over 350 peer-selected technical presentations covering all topical issues in the oil and gas (Energy) sector the OTC delivers a unique experience for investors to develop business relationships and tap into emerging regions that are vital to offshore development. Generally, At the OTC, intending investors learn more about the investments. The conference is held annually in Houston, NRG Park (Reliant Park) the OTC is sponsored by 13-industry organizations and societies including those from Africa who work cooperatively to develop the technical program. These 13 industry organizations determine the date of the event."}
                Link={'https://www.gabrieldomale.com/blog/investing-in-africa-oil-gas-top-5-forums-you-should-attend'}
                LinkText={'GABRIEL DOMALE CONSULTING'}/>
            <SubscriptionBox />
            <Footer />
        </div>
    )
}

export default ArticleOne